<template>
  <main class="mr-4">
    <trac-back-button>Back</trac-back-button>
    <trac-loading v-if="isLoading"></trac-loading>

    <h3 class="my-8 font-bold text-sm">KYC (Manage Wallet PIN)</h3>

    <div class="shadow-lg rounded-md p-4 pt-8 md:w-9/12 mx-auto my-8 md:px-12">
      <h3 class="my-5 font-bold text-primaryBlue">Manage Wallet PIN</h3>
      <div class="grid md:grid-cols-3 md:mx-10 my-12 gap-4">
        <div
          @click="gotoConfirmPasswordPage"
          class="
            shadow-md
            rounded-md
            p-5
            cursor-pointer
            hover:bg-accentLight
            flex
            items-center
            justify-between
            gap-3
          "
        >
          <div class="flex items-center gap-3">
            <img
              src="./../../../assets/svg/pin-management/settlement-pref.svg"
              alt=""
            />
            <p class="text-xs">Change Wallet Pin</p>
          </div>
          <img
            src="./../../../assets/svg/pin-management/chevron-icon-right.svg"
            alt=""
          />
        </div>
        <div
          @click="
            $router.push({
              name: 'ConfirmPassword',
              query: {
                ops: 'forgot_wallet_pin',
              },
            })
          "
          class="
            shadow-md
            rounded-md
            p-5
            cursor-pointer
            hover:bg-accentLight
            flex
            items-center
            justify-between
            gap-3
          "
        >
          <div class="flex items-center gap-3">
            <img
              src="./../../../assets/svg/pin-management/settlement-pref.svg"
              alt=""
            />
            <p class="text-xs">Forgot Wallet Pin</p>
          </div>
          <img
            src="./../../../assets/svg/pin-management/chevron-icon-right.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
} from "../../../browser-db-config/localStorage";
import { eventBus } from "./../../../main";

export default {
  computed: {},
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    gotoConfirmPasswordPage() {
      this.$router.push({
        name: "ConfirmPassword",
        query: {
          ops: "change_wallet_pin",
        },
      });
    },
    // async sendOTP() {
    //   this.isLoading = true;

    //   const res = await this.$store.dispatch('SEND_WALLET_OTP', {
    //     businessId: GET_USER_BUSINESS_ID(),
    //     verificationType: 'PHONE',
    //     reference: GET_USER_BUSINESS_DATA()['phone'],
    //   });

    //   if (res.status) {
    //     this.$router.push({
    //       name: "ConfirmPassword",
    //       query: {
    //         ops: "enter_otp",
    //       },
    //     });
    //   } else {
    //     eventBus.$emit("trac-alert", {
    //       message: res.message || "Error: Couldn't send OTP.",
    //     });
    //   }
    //   this.isLoading = false;
    // },
  },
};
</script>

<style>
</style>